import {
  Box,
  Flex,
  Link,
  useColorMode,
  color,
  spacing,
  PrimaryText,
} from 'deepstash-ui';
import NextLink from 'src/components/navigation/NextLink';
import React, { useMemo } from 'react';
import useProfile from 'src/providers/hooks/useProfile';
import { UserData } from 'types/models';
import { fragmentTextWithEmojis } from 'utils/StringUtils';
import ProfilePicture from 'src/components/social/ProfilePicture';
import FollowUserButton from 'src/components/buttons/FollowUserButton';

interface OwnProps {
  user: UserData;
  index: number;
}

const UserInfo: React.FC<OwnProps> = ({ user, index }) => {
  const { colorMode } = useColorMode();
  const { profile } = useProfile();

  const bioFragments = useMemo(
    () => fragmentTextWithEmojis(user.bio ?? ''),
    [user],
  );

  if (!user) return null;

  const displayUser = (
    <Flex _focus={undefined} _active={undefined}>
      <ProfilePicture
        m={spacing.toRem(2)}
        photo={user.photo ?? ''}
        size="l"
        imageBlurhash={user.photo_blurhash}
      />
      <Flex ml="8px" flexDir="column">
        <PrimaryText
          fontSize="16px"
          lineHeight="19px"
          fontWeight="bold"
          color={color[colorMode].primary}
          _hover={{ textDecoration: 'underline' }}
          textOverflow="ellipsis"
          noOfLines={1}
        >
          {'@' + user.username}
        </PrimaryText>
        <PrimaryText
          color={color[colorMode].textSecondary}
          textOverflow="ellipsis"
          noOfLines={1}
        >
          {!!user.firstName || !!user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.username}
        </PrimaryText>
        <Flex textAlign="center" w="100%">
          {bioFragments.map(fragment => (
            <PrimaryText
              size="s"
              color={color[colorMode].textDisabled}
              textOverflow="ellipsis"
              noOfLines={1}
              key={fragment}
            >
              {fragment}
            </PrimaryText>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <Box
      bgColor={color[colorMode].top}
      p={spacing.toRem(16)}
      borderRadius={spacing.toRem(20)}
      mb={spacing.toRem(12)}
      key={index}
    >
      <Flex
        justifyContent="space-between"
        alignItems="start"
        _focus={undefined}
        _active={undefined}
        wordBreak="break-all"
      >
        <NextLink href={`/u/${user.username}`} passHref>
          <Link
            w="100%"
            _focus={undefined}
            _active={undefined}
            _hover={undefined}
          >
            {displayUser}
          </Link>
        </NextLink>
        <FollowUserButton
          display={profile?.id === user.id ? 'none' : 'inline-flex'}
          userId={user.id}
          size="sm"
          px={spacing.toRem(12)}
          py={spacing.toRem(6)}
        />
      </Flex>
    </Box>
  );
};

export default UserInfo;
