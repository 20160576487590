import React from 'react';
import { Flex, Heading, typography, Text, color } from 'deepstash-ui';
import HeroCarouselCard from './HeroCarouselCard';
import { LANDING_HERO_CARDS } from '../landing-hero-cards';

const IMAGE_SIZE = { WIDTH: 100, HEIGHT: 124, BORDER_RADIUS: 16 };

const SUBTITLE =
  'Unlimited access to 200,000+ powerful ideas from top books, podcasts, and more.';

interface LandingHeroSectionMobileProps {}

const LandingHeroSectionMobile: React.FC<
  LandingHeroSectionMobileProps
> = ({}) => {
  const renderHeroCarouselCard = ({
    card,
    index,
  }: {
    card: { imageUrl: string; name: string };
    index: number;
  }) => {
    return (
      <HeroCarouselCard
        key={`first-row-${index}`}
        imageProps={{
          src: card.imageUrl,
          width: IMAGE_SIZE.WIDTH,
          height: IMAGE_SIZE.HEIGHT,
          layout: 'fixed',
          style: { borderRadius: IMAGE_SIZE.BORDER_RADIUS },
          priority: true,
        }}
        containerWidth={IMAGE_SIZE.WIDTH}
        containerBorderBottomRadius={IMAGE_SIZE.BORDER_RADIUS}
      />
    );
  };

  const renderImages = () => {
    return (
      <Flex overflow="hidden" flexDirection="column" gridRowGap={16}>
        <Flex
          flexWrap="nowrap"
          height={IMAGE_SIZE.HEIGHT}
          justifyContent={'space-evenly'}
        >
          {LANDING_HERO_CARDS.slice(0, 3).map((card, index) => {
            return renderHeroCarouselCard({ card, index });
          })}
        </Flex>
        <Flex flexWrap="nowrap" justifyContent={'space-evenly'}>
          {LANDING_HERO_CARDS.slice(3, 6).map((card, index) => {
            return renderHeroCarouselCard({ card, index });
          })}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex flexDirection="column" gridRowGap={16}>
      {renderImages()}
      <Flex flexDirection="column" alignItems="center" px={16}>
        <Heading
          size="h1"
          color={color.light.text}
          {...typography.primaryHeadingFonts.xl.bold}
          lineHeight="1.125em"
          fontWeight="800"
          fontSize={'2rem'}
          textAlign="center"
        >
          BECOME THE SMARTEST
          <br />
          IN THE ROOM
        </Heading>
        <Text
          {...typography.primaryHeadingFonts.small.regular}
          fontSize={'1.2rem'}
          color={color.light.textSecondary}
          textAlign="center"
          mt={16}
        >
          {SUBTITLE}
        </Text>
      </Flex>
    </Flex>
  );
};

export default LandingHeroSectionMobile;
