import React from 'react';
import { Flex, color, typography } from 'deepstash-ui';
import Navbar from 'src/components/navbar/Navbar';
import Footer from 'src/components/layout/components/Footer';
import LandingHeroSectionMobile from './components-v2/LandingHeroSectionMobile';
import LandingStartTrialSection from './components-v2/LandingStartTrialSection';
import LandingValuePropsSection from './components-v2/LandingValuePropsSection';
import LandingStartJourneySection from './components-v2/LandingStartJourneySection';
import LandingTopicsSection from './components-v2/LandingTopicsSection';
import LandingProSection from './components-v2/LandingProSection';
import LandingReviewsSection from './components-v2/LandingReviewsSection';
import LandingCollectionsSections from './components-v2/LandingCollectionsSections';
import OverallFaq from 'src/components/faq/OverallFaq';
import LandingGoalQuestion from './components-v2/LandingGoalQuestion';
import SpecialOfferBannerWrapper from '../special-offer/SpecialOfferBannerWrapper';

interface LandingPageMobileV2Props {}

const LandingPageMobileV2: React.FC<LandingPageMobileV2Props> = ({}) => {
  return (
    <>
      <Flex width="100%" flexDirection="column" pb={16}>
        <Flex paddingX={16}>
          <Navbar />
        </Flex>
        <LandingHeroSectionMobile />
        <Flex
          height={4}
          bgColor={color.light.underground}
          borderRadius={4}
          mx={16}
          my={24}
        />
        <LandingGoalQuestion px={16} />
        <LandingStartTrialSection />
        <LandingValuePropsSection />
        <LandingStartJourneySection />
        <LandingCollectionsSections />
        <LandingTopicsSection
          topicChipTextProps={{ ...typography.primaryTextFonts.small.regular }}
        />
        <LandingProSection
          showProComparison={false}
          boxTextProps={{ ...typography.primaryTextFonts.medium.regular }}
        />
        <LandingReviewsSection />
        <OverallFaq mt={48} px={16} />
      </Flex>
      <Footer
        backgroundColor={color.light.surface}
        disableStickyStartTrial={true}
      />

      {/* Manually enabled during sales */}
      <SpecialOfferBannerWrapper />
    </>
  );
};

export default LandingPageMobileV2;
