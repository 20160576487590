import { color, Flex, spacing, Text, useColorMode } from 'deepstash-ui';
import React from 'react';
import Chip from 'src/components/chips/Chip';
import SourceCollapsed from 'src/components/source/source-collapsed/SourceCollapsed';
import { SearchedHashtag } from 'types/models';
import { BLOCK_WIDTH_REM, GAP_SIZE_REM, numberFormatter } from 'utils/global';
import { getSingularOrPlural } from 'utils/StringUtils';

interface HashtagSearchedResultDesktopProps {
  searchedHashtag: SearchedHashtag;
}

const HashtagSearchedResultDesktop: React.FC<
  HashtagSearchedResultDesktopProps
> = ({ searchedHashtag }) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      flexDir="column"
      align="flex-start"
      mb={BLOCK_WIDTH_REM}
      overflow="hidden"
    >
      <Flex alignItems="center" gridGap={spacing.M.rem}>
        <Chip
          content={'#' + searchedHashtag.name}
          href={`topic/${searchedHashtag.name}`}
          m="0"
        />
        <Text fontSize="0.8em" color={color[colorMode].textDisabled}>
          {numberFormatter(searchedHashtag.totalIdeas)}{' '}
          {getSingularOrPlural(searchedHashtag.totalIdeas, 'idea', 'ideas')}
        </Text>
      </Flex>
      <Flex
        mt={GAP_SIZE_REM}
        gridGap={GAP_SIZE_REM}
        width={'100%'}
        overflow={{ base: 'scroll', md: 'hidden' }}
      >
        {(searchedHashtag.sources ?? []).slice(0, 4).map(source => (
          <SourceCollapsed size="small" key={source.id} source={source} />
        ))}
      </Flex>
    </Flex>
  );
};

export default HashtagSearchedResultDesktop;
