import { SearchResponse } from 'api/api.types';
import { Box, Flex, spacing, useIsMobileView } from 'deepstash-ui';
import { SearchFeed } from 'hooks/search/useSearchScroll';
import dynamic from 'next/dynamic';
import { SearchType } from 'pages/search';
import React, { ReactElement, useCallback } from 'react';
import SourceCollapsed from 'src/components/source/source-collapsed/SourceCollapsed';
import SourcePreview from 'src/components/source/source-preview/SourcePreview';
import { Idea, SearchedHashtag, Source, UserData } from 'types/models';
import { isSourceEmpty } from 'utils/global';
import HashtagSearchedResultDesktop from './components/HashtagSearchedResultMobile';
import UserInfo from './components/UserInfo';

const SearchPageMobile = dynamic(() => import('./SearchPageMobile'));
const SearchPageDesktop = dynamic(() => import('./SearchPageDesktop'));
SearchPageDesktop.displayName = 'SearchPageDesktop';
SearchPageMobile.displayName = 'SearchPageMobile';
interface SearchPageProps {
  initialApiData?: SearchResponse;
  /**
   * The search type parsed server side
   */
  initialSearchType?: SearchType;
}

const SearchPage: React.FC<SearchPageProps> = ({
  initialApiData,
  initialSearchType,
}) => {
  const isMobileView = useIsMobileView();

  /**
   * Render the search results
   * @param feed The results to be rendered
   * @param isFetching Whether or not we are currently fetching more results
   * @param searchType What we are searching for
   * @param errorMessage A component to be shown if no results were found
   */
  const renderFeed = useCallback(
    (
      feed: SearchFeed,
      isFetching: boolean,
      searchType: SearchType,
      errorMessage: ReactElement,
    ) => (
      <Box>
        {!isFetching && (!feed || feed.length === 0) ? (
          errorMessage
        ) : (
          <Box>
            {searchType === 'article' &&
              (feed as Source[]).map(source => {
                if (isSourceEmpty(source)) return null;

                if (searchType === 'article') {
                  return (
                    <Box key={`articleCard$${source.id}`} width="100%">
                      <SourceCollapsed source={source} mb={spacing.XXXL.rem} />
                    </Box>
                  );
                }
                return null;
              })}
            {searchType === 'block' && (
              <Flex flexDir="column" width="100%" alignItems="center">
                {(feed as Idea[])?.map(idea => {
                  const { source } = idea;

                  if (!source || isSourceEmpty(source)) return null;

                  return (
                    <Box width="100%" key={source.id}>
                      <SourcePreview
                        source={source}
                        ideaId={idea.id}
                        mb={spacing.XXXL.rem}
                      />
                    </Box>
                  );
                })}
              </Flex>
            )}
            {searchType === 'user' && (
              <Box px={{ base: '1rem', lg: 0 }}>
                {(feed as UserData[]).map((user: UserData, index: number) => (
                  <UserInfo user={user} key={user.id} index={index} />
                ))}
              </Box>
            )}
            {searchType === 'hashtag' && (
              <Box px={{ base: '1rem', lg: 0 }}>
                {(feed as SearchedHashtag[]).map((searchedHashtag, index) => (
                  <HashtagSearchedResultDesktop
                    key={`SearchHashtagSection-${searchedHashtag.name}-${index}`}
                    searchedHashtag={searchedHashtag}
                  />
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    ),
    [],
  );

  return isMobileView ? (
    <SearchPageMobile
      initialSearchType={initialSearchType}
      renderFeed={renderFeed}
      initialApiData={initialApiData}
    />
  ) : (
    <SearchPageDesktop
      initialSearchType={initialSearchType}
      renderFeed={renderFeed}
      initialApiData={initialApiData}
    />
  );
};

export default SearchPage;
