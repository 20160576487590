import { SearchResponse } from 'api/api.types';
import SearchPage from 'src/page-components/search/SearchPage';
import { NextPage } from 'next';
import React from 'react';

export type SearchType = 'article' | 'block' | 'user' | 'hashtag';
// The query params for the search pages
export type SearchTypeQueryParam = 'ideas' | 'people' | 'topics' | 'sources';

export interface MenuOption {
  title: string;
  key: SearchType;
}

export interface OwnerOption {
  title: string;
  key: 'all' | 'own';
}
export const MENU_OPTIONS: MenuOption[] = [
  {
    title: 'Ideas',
    key: 'block',
  },
  {
    title: 'People',
    key: 'user',
  },
  {
    title: 'Topics',
    key: 'hashtag',
  },
  {
    title: 'Sources',
    key: 'article',
  },
];

export const OWNER_OPTIONS: OwnerOption[] = [
  {
    title: 'All',
    key: 'all',
  },
  {
    title: 'Own',
    key: 'own',
  },
];

interface OwnProps {
  initialApiData: SearchResponse | null;
  initialSearchType: SearchType | null;
}

const Search: NextPage<OwnProps> = ({ initialApiData, initialSearchType }) => {
  return (
    <SearchPage
      initialApiData={initialApiData ?? undefined}
      initialSearchType={initialSearchType ?? undefined}
    />
  );
};

// export const getServerSideProps: GetServerSideProps<OwnProps> = async ctx => {
//   const { q, owner, tab } = ctx.query;
//   const { token } = cookie.parse(ctx.req.headers.cookie ?? '');

//   if (!q) {
//     return {
//       props: {
//         initialApiData: null,
//         initialSearchType: null,
//       },
//     };
//   }

//   //Get rid of the # in the query
//   let parsedSearchText = q as string;

//   if (parsedSearchText.includes('#')) {
//     parsedSearchText = replaceAll(parsedSearchText, '#', '');
//   }

//   //Get the search type based on the query param
//   const searchType =
//     MENU_OPTIONS.find(
//       option => option.title.toLowerCase() === (tab as SearchType),
//     )?.key ?? 'block';

//   const res = await search(
//     parsedSearchText,
//     searchType,
//     owner as 'all' | 'own',
//     token,
//   );

//   return {
//     props: {
//       initialApiData: res ?? null,
//       initialSearchType: searchType,
//     },
//   };
// };

export default Search;
