import { ReadFeedApiResponse } from 'api/api.types';
import { useIsMobileView, Flex } from 'deepstash-ui';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import useAuth from 'providers/hooks/useAuth';
import React from 'react';
import { Analytics, Events } from 'services/analytics';
import HeadMetadata from 'src/components/metadata/HeadMetadata';
import homePageGetServerSideProps from 'src/page-components/home/utils/homePageGetServerSideProps';
import LandingPageMobileV2 from 'src/page-components/landing/LandingPageMobileV2';
import LandingPageV2 from 'src/page-components/landing/LandingPageV2';
import { getHomePageMetadata } from 'utils/metadata.utils';

const HomePage = dynamic(() => import('../src/page-components/home/HomePage'));

export interface HomePageServerSideProps {
  sections?: ReadFeedApiResponse;
}

const Home: React.FC<HomePageServerSideProps> = ({ sections }) => {
  const { isLoggedIn } = useAuth();
  const isMobileView = useIsMobileView();

  React.useEffect(() => {
    if (!isLoggedIn) {
      Analytics.logEvent({
        eventName: Events.consumption.viewLanding,
        platforms: ['amplitude'],
      });
    }
  }, []);

  // We want to render the landing page if
  // the user is not logged in
  if (!isLoggedIn) {
    return (
      <Flex flexDirection="column" width={'100%'}>
        <HeadMetadata pageMetadata={getHomePageMetadata()} />
        {isMobileView ? <LandingPageMobileV2 /> : <LandingPageV2 />}
      </Flex>
    );
  }

  return <HomePage sections={sections} />;
};

export const getServerSideProps: GetServerSideProps<HomePageServerSideProps> =
  homePageGetServerSideProps();

export default Home;
